import React, { useEffect, useState } from 'react';
import bridge from '@vkontakte/vk-bridge';
import {  Avatar, Button, ButtonGroup, Card, Div, Headline, SimpleCell, Spinner, Title  } from '@vkontakte/vkui';
import { Icon28ShareOutline } from '@vkontakte/icons';

export default function User ({name, photo, percent, shareStorie}) {
	const [loadedPhoto, setPhoto] = useState(null)
    const [userName, setUserName] = useState(null)
    
    useEffect(() => {
        setPhoto(null)
        let i = new Image();
        i.onload = function(){setPhoto(i.src)};
        i.src = photo
    }, [photo])
    return (
        <SimpleCell
            disabled
            multiline
            before={<Avatar size={72} src={loadedPhoto !== null ? loadedPhoto : null}>
                {loadedPhoto !== null ? null : <Spinner />}
            </Avatar>}
            subtitle={<span style={{fontSize: "1rem", color: "var(--accent)"}}>Совместимость {percent}%</span>}
            after={loadedPhoto && <Icon28ShareOutline onClick={() => {shareStorie(percent, photo)}} style={{cursor: "pointer"}}/>}
            >
                <Title weight='2' level='2'>{name}</Title>
        </SimpleCell>
    );
}
